import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../mobileHeader.module.sass';
import { Anchor, Text } from '../../../../Text';
import { useMarketingStore } from '../../../../../providers/RootStoreProvider';

export const MobileHeaderCruises = observer(
  ({ isSea, shipsSea, popularPopdirs, urlBF }) => {
    const store = useMarketingStore();

    const allCruises = isSea || shipsSea ? '/result?type=sea' : '/result';
    const allStartCities = isSea || shipsSea ? '/popdir?type=sea' : '/popdir';

    useEffect(() => {
      store.setIsSea(isSea);
      store.getRiverDistention();
    }, [isSea]);

    const popularDir =
      popularPopdirs?.popularDirections || store.popularDirections;
    const startCities =
      popularPopdirs?.departureCities || store.departureCities;

    return (
      <>
        <a
          className={
            urlBF === 'black_friday' ? styles.allLinksBf : styles.allLinks
          }
          href={allCruises}
          rel="noopener noreferrer"
        >
          Все круизы
        </a>
        {isSea ? (
          <Text style={{ paddingTop: 16, paddingBottom: 0 }} weight="bold">
            Города отправления
          </Text>
        ) : (
          <div className={styles.seasonItemWrapper}>
            <Anchor
              href={allStartCities}
              variant="text-5"
              className={urlBF === 'black_friday' && styles.colorText}
            >
              Города отправления
            </Anchor>
            <div
              className={
                urlBF === 'black_friday'
                  ? styles.arrowRightMobileBf
                  : styles.arrowRightMobile
              }
            >
              →
            </div>
          </div>
        )}
        <ul className={styles.featureList}>
          {startCities.map((startCity, key) => (
            <li key={key}>
              <Anchor
                href={`/popdir/${startCity.href}${isSea ? '' : '/result'}`}
                rel="noreferrer"
                className={urlBF === 'black_friday' && styles.colorText}
              >
                {startCity.h1}
              </Anchor>
            </li>
          ))}
        </ul>
        {isSea ? (
          <Text style={{ paddingTop: 16, paddingBottom: 0 }} weight="bold">
            Популярные направления
          </Text>
        ) : (
          <div className={styles.seasonItemWrapper}>
            <Anchor
              href={'/poproutes'}
              variant="text-5"
              className={urlBF === 'black_friday' && styles.colorText}
            >
              Популярные направления
            </Anchor>
            <div
              className={
                urlBF === 'black_friday'
                  ? styles.arrowRightMobileBf
                  : styles.arrowRightMobile
              }
            >
              →
            </div>
          </div>
        )}
        <ul className={styles.featureList}>
          {popularDir?.map((popDir, key) => (
            <li key={key}>
              <Anchor
                href={`/popdir/${popDir.href}${isSea ? '' : '/result'}`}
                rel="noreferrer"
                className={urlBF === 'black_friday' && styles.colorText}
              >
                {popDir.h1}
              </Anchor>
            </li>
          ))}
          <li>
            {!isSea && (
              <Anchor
                href={'/expeditionsCruise'}
                color="primaryColor"
                variant="heading-3"
                className={
                  urlBF === 'black_friday'
                    ? styles.itemWrapperBf
                    : styles.itemWrapper
                }
              >
                Экспедиционные круизы →
                {/* <Icon
                  leftGap={10}
                  topGap={2}
                  icon={
                    <IconSVG name={'common/big-right'} width={16} height={16} />
                  }
                  height={11}
                  width={11}
                  title="Все круизы"
                /> */}
              </Anchor>
            )}
          </li>
          {/* <li> */}
          {/*   {!isSea && ( */}
          {/*     <> */}
          {/*       <div className={styles.itemWrapper}> */}
          {/*         <Anchor */}
          {/*           href={'/twentyFourthYear'} */}
          {/*           variant="heading-3" */}
          {/*           className={ */}
          {/*             urlBF === 'black_friday' */}
          {/*               ? styles.seasonBf */}
          {/*               : styles.seasonItem */}
          {/*           } */}
          {/*         > */}
          {/*           Круизы на 2024 год → */}
          {/*           /!* <span className={styles.arrowRight}>→</span> *!/ */}
          {/*         </Anchor> */}
          {/*       </div> */}

          {/*       <div> */}
          {/*         /!* <div> */}
          {/*         <div className={styles.season}> */}
          {/*           <Anchor */}
          {/*             href={'/2024/spring'} */}
          {/*             target={isMobile ? '' : '_blank'} */}
          {/*             variant={'text-4'} */}
          {/*             className={styles.seasonItem} */}
          {/*           > */}
          {/*             Весна */}
          {/*           </Anchor> */}
          {/*         </div> */}
          {/*         <div className={styles.seasonBlock}> */}
          {/*              <div> */}
          {/*             <Anchor */}
          {/*               href={'/2024/spring/april'} */}
          {/*               target={isMobile ? '' : '_blank'} */}
          {/*               variant={'text'} */}
          {/*               className={styles.seasonItem} */}
          {/*             > */}
          {/*               Апрель */}
          {/*             </Anchor> */}
          {/*           </div> */}
          {/*           <div> */}
          {/*             <Anchor */}
          {/*               href={'/2024/spring/may'} */}
          {/*               target={isMobile ? '' : '_blank'} */}
          {/*               variant={'text'} */}
          {/*               className={styles.seasonItem} */}
          {/*             > */}
          {/*               Май */}
          {/*             </Anchor> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*       </div> *!/ */}
          {/*         <div> */}
          {/*           <div className={styles.season}> */}
          {/*             <Anchor */}
          {/*               href={'/2024/summer'} */}
          {/*               variant={'text-4'} */}
          {/*               className={ */}
          {/*                 urlBF === 'black_friday' */}
          {/*                   ? styles.seasonBf */}
          {/*                   : styles.seasonItem */}
          {/*               } */}
          {/*             > */}
          {/*               Лето */}
          {/*             </Anchor> */}
          {/*           </div> */}
          {/*           <div className={styles.seasonBlock}> */}
          {/*             /!* <div> */}
          {/*             <Anchor */}
          {/*               href={'/2024/summer/june'} */}
          {/*               target={isMobile ? '' : '_blank'} */}
          {/*               variant={'text'} */}
          {/*               className={styles.seasonItem} */}
          {/*             > */}
          {/*               Июнь */}
          {/*             </Anchor> */}
          {/*           </div> *!/ */}
          {/*             <div> */}
          {/*               <Anchor */}
          {/*                 href={'/2024/summer/july'} */}
          {/*                 variant={'text'} */}
          {/*                 className={ */}
          {/*                   urlBF === 'black_friday' */}
          {/*                     ? styles.seasonBf */}
          {/*                     : styles.seasonItem */}
          {/*                 } */}
          {/*               > */}
          {/*                 Июль */}
          {/*               </Anchor> */}
          {/*             </div> */}
          {/*             <div> */}
          {/*               <Anchor */}
          {/*                 href={'/2024/summer/august'} */}
          {/*                 variant={'text'} */}
          {/*                 className={ */}
          {/*                   urlBF === 'black_friday' */}
          {/*                     ? styles.seasonBf */}
          {/*                     : styles.seasonItem */}
          {/*                 } */}
          {/*               > */}
          {/*                 Август */}
          {/*               </Anchor> */}
          {/*             </div> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*         <div> */}
          {/*           <div className={styles.season}> */}
          {/*             <Anchor */}
          {/*               href={'/2024/autumn'} */}
          {/*               variant={'text-4'} */}
          {/*               className={ */}
          {/*                 urlBF === 'black_friday' */}
          {/*                   ? styles.seasonBf */}
          {/*                   : styles.seasonItem */}
          {/*               } */}
          {/*             > */}
          {/*               Осень */}
          {/*             </Anchor> */}
          {/*           </div> */}
          {/*           <div className={styles.seasonBlock}> */}
          {/*             <div> */}
          {/*               <Anchor */}
          {/*                 href={'/2024/autumn/september'} */}
          {/*                 variant={'text'} */}
          {/*                 className={ */}
          {/*                   urlBF === 'black_friday' */}
          {/*                     ? styles.seasonBf */}
          {/*                     : styles.seasonItem */}
          {/*                 } */}
          {/*               > */}
          {/*                 Сентябрь */}
          {/*               </Anchor> */}
          {/*             </div> */}
          {/*             <div> */}
          {/*               <Anchor */}
          {/*                 href={'/2024/autumn/october'} */}
          {/*                 variant={'text'} */}
          {/*                 className={ */}
          {/*                   urlBF === 'black_friday' */}
          {/*                     ? styles.seasonBf */}
          {/*                     : styles.seasonItem */}
          {/*                 } */}
          {/*               > */}
          {/*                 Октябрь */}
          {/*               </Anchor> */}
          {/*             </div> */}
          {/*             <div> */}
          {/*               <Anchor */}
          {/*                 href={'/2024/autumn/november'} */}
          {/*                 variant={'text'} */}
          {/*                 className={ */}
          {/*                   urlBF === 'black_friday' */}
          {/*                     ? styles.seasonBf */}
          {/*                     : styles.seasonItem */}
          {/*                 } */}
          {/*               > */}
          {/*                 Ноябрь */}
          {/*               </Anchor> */}
          {/*             </div> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*       </div> */}
          {/*     </> */}
          {/*   )} */}
          {/* </li> */}
          <li>
            {!isSea && (
              <>
                <div className={styles.itemWrapper}>
                  <Anchor
                    href={'/2025'}
                    variant="heading-3"
                    className={
                      urlBF === 'black_friday'
                        ? styles.seasonBf
                        : styles.seasonItem
                    }
                  >
                    Круизы на 2025 год
                    <span className={styles.arrowRight}>→</span>
                  </Anchor>
                </div>

                <div>
                  <div>
                    <div className={styles.season}>
                      <Anchor
                        href={'/2025/spring'}
                        variant={'text-4'}
                        className={
                          urlBF === 'black_friday'
                            ? styles.seasonBf
                            : styles.seasonItem
                        }
                      >
                        Весна
                      </Anchor>
                    </div>
                    <div className={styles.seasonBlock}>
                      <div>
                        <Anchor
                          href={'/2025/spring/april'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Апрель
                        </Anchor>
                      </div>
                      <div>
                        <Anchor
                          href={'/2025/spring/may'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Май
                        </Anchor>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.season}>
                      <Anchor
                        href={'/2025/summer'}
                        variant={'text-4'}
                        className={
                          urlBF === 'black_friday'
                            ? styles.seasonBf
                            : styles.seasonItem
                        }
                      >
                        Лето
                      </Anchor>
                    </div>
                    <div className={styles.seasonBlock}>
                      <div>
                        <Anchor
                          href={'/2025/summer/june'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Июнь
                        </Anchor>
                      </div>
                      <div>
                        <Anchor
                          href={'/2025/summer/july'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Июль
                        </Anchor>
                      </div>
                      <div>
                        <Anchor
                          href={'/2025/summer/august'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Август
                        </Anchor>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={styles.season}>
                      <Anchor
                        href={'/2025/autumn'}
                        variant={'text-4'}
                        className={
                          urlBF === 'black_friday'
                            ? styles.seasonBf
                            : styles.seasonItem
                        }
                      >
                        Осень
                      </Anchor>
                    </div>
                    <div className={styles.seasonBlock}>
                      <div>
                        <Anchor
                          href={'/2025/autumn/september'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Сентябрь
                        </Anchor>
                      </div>
                      <div>
                        <Anchor
                          href={'/2025/autumn/october'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Октябрь
                        </Anchor>
                      </div>
                      <div>
                        <Anchor
                          href={'/2025/autumn/november'}
                          variant={'text'}
                          className={
                            urlBF === 'black_friday'
                              ? styles.seasonBf
                              : styles.seasonItem
                          }
                        >
                          Ноябрь
                        </Anchor>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </li>
        </ul>
      </>
    );
  },
);
