import React, { useState, useEffect } from 'react';
import cc from 'classcat';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { Login } from '../Login';
import { Anchor, Text } from '../../../Text';
import { Icon } from '../../../Icon';
import styles from './mobileHeader.module.sass';
import { useSearchStore } from '../../../../providers/SearchStoreProvider';
import { MobileHeaderCruises } from './MobileHeaderCruises/MobileHeaderCruises';
import { MobileHeaderShips } from './MobileHeaderShips/MobileHeaderShips';
import { useAuthStore } from '../../../../providers/RootStoreProvider';
import { Logo } from '../../../Logo';
import { IconSVG } from '../../../SpriteSVG';
import { SkeletonCover } from '../../../Skeleton/SkeletonCover';
import { isClient } from '../../../../utils/isClient';
import { useCheckStep1Unregistered } from '../../../../hooks/useCheckStep1Unregistered';

export const MobileHeader = observer(
  ({
    isMobile,
    shipsSea,
    isOutsPage,
    sea,
    topContent,
    showTopContent = false,
    popularPopdirs,
    urlBF,
  }) => {
    const isUnregistered = useCheckStep1Unregistered();
    const [open, setOpen] = useState(false);
    const [openCruizeMenu, setOpenCruizeMenu] = useState(false);
    const [openShipsMenu, setOpenShipsMenu] = useState(false);
    const [hasBanner, setHasBanner] = useState(showTopContent);
    const { mainResult } = useSearchStore();
    const firstLoading = mainResult.isFirstLoading;
    const store = useAuthStore();
    const router = useRouter();
    const { type } = router.query;
    const isSea =
      sea ||
      type === 'sea' ||
      router.asPath.startsWith('/sea_cruise') ||
      router.asPath.startsWith('/sea');
    const activePage = router.pathname;

    useEffect(() => {
      mainResult.setLoading();
    }, []);

    return (
      <div>
        <header
          className={
            !showTopContent || open ? styles.wrapper : styles.wrapperBanner
          }
        >
          <div
            className={
              !open && showTopContent ? styles.displayFlex : styles.displayNone
            }
          >
            {!open && showTopContent && <div>{isClient && topContent}</div>}
          </div>

          <div
            className={
              urlBF === 'black_friday'
                ? styles.contactsBarBf
                : styles.contactsBar
            }
            id="np"
          >
            <div className={styles.timeTableContent}>
              <div className={styles.timeTable}>
                {!isSea && !shipsSea && (
                  <div className={styles.InTimeTable}>
                    <Icon
                      rightGap={8}
                      icon={
                        <IconSVG
                          name={'common/timetable'}
                          width={20}
                          height={20}
                        />
                      }
                      title="Расписание отправлений"
                    />
                    <a
                      className={styles.timeTableNone}
                      href="/departures"
                      rel="noreferrer"
                    >
                      <Text
                        variant="timeTable"
                        style={
                          urlBF === 'black_friday'
                            ? { color: '#fff' }
                            : { color: 'none' }
                        }
                      >
                        Расписание
                      </Text>
                    </a>
                  </div>
                )}
              </div>
            </div>
            {!store.isB2bMode && (
              <div className={styles.phoneWatsApp}>
                <div className="header-phone">
                  <a
                    href="tel:84958775834"
                    style={
                      urlBF === 'black_friday'
                        ? { color: '#fff' }
                        : { color: 'none' }
                    }
                  >
                    +7 495 877-58-34
                  </a>
                </div>
                {/* <div className={styles.socialIcon}>
                  <a
                    href="https://api.whatsapp.com/send?phone=79397100589&text=%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D1%8E%D1%82%20%D1%80%D0%B5%D1%87%D0%BD%D1%8B%D0%B5%20%D0%BA%D1%80%D1%83%D0%B8%D0%B7%D1%8B"
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      // leftGap={18}
                      icon={
                        <IconSVG name={'social/wa'} width={24} height={24} />
                      }
                      title="whatsapp"
                    />
                  </a>
                </div> */}
              </div>
            )}
          </div>
          <div
            className={
              urlBF === 'black_friday' ? styles.headerNavBf : styles.headerNav
            }
          >
            {!store.isB2bMode && <Logo w={124} h={40} urlBF={urlBF} />}
            {/* <div className={styles.actions}>
            <Login isMobile={isMobile} isOutsPage={isOutsPage} />
          </div> */}
            {!firstLoading && !isUnregistered ? (
              !open && (
                <div className={styles.actions}>
                  <Login
                    isMobile={isMobile}
                    isOutsPage={isOutsPage}
                    urlBF={urlBF}
                  />
                </div>
              )
            ) : (
              <div className={styles.actions}>
                {' '}
                <SkeletonCover width={88} height={29} />
              </div>
            )}
            <button
              onClick={() => {
                setOpen(!open);
                setOpenCruizeMenu(false);
                setOpenShipsMenu(false);
                // eslint-disable-next-line no-unused-expressions
                topContent && setHasBanner(!hasBanner);
              }}
              type="button"
              className={cc([
                styles.hamburger,
                styles.hamburgerSpin,
                {
                  [styles.isOpen]: open,
                  [styles.hamburgerBf]: urlBF === 'black_friday',
                },
              ])}
            >
              <span className={styles.hamburgerBox}>
                <span
                  className={
                    urlBF === 'black_friday'
                      ? styles.hamburgerBfInner
                      : styles.hamburgerInner
                  }
                />
              </span>
            </button>
          </div>
        </header>
        <div
          className={cc([
            styles.mobileDropDown,
            {
              [styles.openMenu]: open,
              [styles.openMenuBf]: urlBF === 'black_friday' && open,
            },
          ])}
        >
          <ul
            className={
              urlBF === 'black_friday'
                ? styles.mobileDropDownListBf
                : styles.mobileDropDownList
            }
          >
            <li
              // className={
              //   activePage.includes('cruise') ||
              //   activePage.includes('/result') ||
              //   activePage.includes('/twentyThirdYear') ||
              //   activePage.includes('/popdir')
              //     ? styles.mobileDropDownListActiveItem
              //     : styles.mobileDropDownListItem
              // }
              // className={
              // activePage.includes('cruise') ||
              // activePage.includes('/result') ||
              // activePage.includes('/twentyThirdYear') ||
              // activePage.includes('/popdir')
              //   ? styles.mobileDropDownListActiveItem
              //   : styles.mobileDropDownListItem

              // styles.mobileDropDownListBfItem
              // }
              className={cc([
                styles.mobileDropDownListItem,
                {
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('cruise'),
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('/result'),
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('/twentyThirdYear'),
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('/popdir'),
                  [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                },
              ])}
            >
              <button
                onClick={() => {
                  setOpenCruizeMenu(!openCruizeMenu);
                }}
                // className={
                //   activePage.includes('cruise') ||
                //   activePage.includes('/result') ||
                //   activePage.includes('/twentyThirdYear') ||
                //   activePage.includes('/popdir')
                //     ? styles.mobileDropDownListActiveItem
                //     : styles.mobileDropDownListItem
                // }
                className={cc([
                  styles.mobileDropDownListItem,
                  {
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('cruise'),
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('/result'),
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('/twentyThirdYear'),
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('/popdir'),
                    [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                  },
                ])}
              >
                Круизы
              </button>
            </li>
            <li
              className={cc([
                styles.mobileDropDownListItem,
                {
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('ships'),
                  [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                },
              ])}
            >
              <button
                onClick={() => {
                  setOpenShipsMenu(true);
                }}
                className={styles.modileDisable}
              >
                {isSea || shipsSea ? 'Лайнеры' : 'Теплоходы'}
              </button>
            </li>
            {!isSea && !shipsSea && (
              <li
                className={cc([
                  styles.mobileDropDownListItem,
                  {
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('discount'),
                    [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                  },
                ])}
              >
                <a
                  href="/discount"
                  rel="noreferrer"
                  style={
                    urlBF === 'black_friday'
                      ? { color: '#fff' }
                      : { color: 'none' }
                  }
                >
                  Скидки и акции
                </a>
              </li>
            )}
            {!store.isB2bMode && (
              <li
                className={cc([
                  styles.mobileDropDownListItem,
                  {
                    [styles.mobileDropDownListActiveItem]:
                      activePage.includes('about'),
                    [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                  },
                ])}
              >
                <a
                  href="/about"
                  rel="noreferrer"
                  style={
                    urlBF === 'black_friday'
                      ? { color: '#fff' }
                      : { color: 'none' }
                  }
                >
                  О компании
                </a>
              </li>
            )}
            <li
              className={cc([
                styles.mobileDropDownListItem,
                {
                  [styles.mobileDropDownListActiveItem]:
                    activePage.includes('/contact'),
                  [styles.mobileDropDownListBfItem]: urlBF === 'black_friday',
                },
              ])}
            >
              <a
                href="/contact"
                rel="noreferrer"
                style={
                  urlBF === 'black_friday'
                    ? { color: '#fff' }
                    : { color: 'none' }
                }
              >
                Контакты
              </a>
            </li>
          </ul>
          <div
            className={cc([
              styles.mobileItem,
              {
                [styles.mobileItemOpen]: openCruizeMenu,
                [styles.mobileItemSea]: isSea,
                [styles.mobileItemSeaOpen]: openCruizeMenu,
                [styles.mobileItemBf]: urlBF === 'black_friday',
              },
            ])}
          >
            <button
              onClick={() => {
                setOpenCruizeMenu(false);
              }}
              className={
                urlBF === 'black_friday' ? styles.backMenuBf : styles.backMenu
              }
            >
              Круизы
            </button>
            <MobileHeaderCruises
              isSea={isSea}
              shipsSea={shipsSea}
              popularPopdirs={popularPopdirs}
              isMobile={isMobile}
              urlBF={urlBF}
            />
          </div>
          <div
            className={cc([
              styles.mobileItem,
              {
                [styles.mobileItemOpen]: openShipsMenu,
                [styles.mobileItemSea]: isSea,
                [styles.mobileItemSeaOpen]: openShipsMenu,
                [styles.mobileItemBf]: urlBF === 'black_friday',
              },
            ])}
          >
            <button
              onClick={() => {
                setOpenShipsMenu(false);
              }}
              className={
                urlBF === 'black_friday' ? styles.backMenuBf : styles.backMenu
              }
            >
              {isSea ? 'Лайнеры' : 'Теплоходы'}
            </button>
            <MobileHeaderShips
              isSea={isSea}
              shipsSea={shipsSea}
              isMobile={isMobile}
              urlBF={urlBF}
            />
          </div>
          {!store.isB2bMode && (
            <div
              className={cc([
                styles.bottomInfo,
                urlBF === 'black_friday' && styles.bottomInfoBf,
                { relative: openCruizeMenu || openShipsMenu },
              ])}
            >
              <div className={styles.innerWrapper}>
                <div className={styles.socialBlock}>
                  <a
                    href="https://t.me/+UYjTvBXN7slkOTZi"
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      icon={
                        <IconSVG name={'social/tg'} width={24} height={24} />
                      }
                      title="telegram"
                    />
                  </a>
                  {/* <a
                    href="https://www.instagram.com/kruiz.online/"
                    target={isMobile ? '' : '_blank'}
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      icon={
                        <IconSVG name={'social/inst'} width={24} height={24} />
                      }
                      title="instagram"
                    />
                  </a> */}
                  <a
                    href="https://vk.com/kruiz.online"
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      icon={
                        <IconSVG name={'social/vk'} width={24} height={24} />
                      }
                      title="Вконтакте"
                    />
                  </a>
                  <a
                    href="https://ok.ru/group/52494082834611"
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      icon={
                        <IconSVG name={'social/ok'} width={24} height={24} />
                      }
                      title="ok"
                    />
                  </a>
                  <a
                    href="https://zen.yandex.ru/id/5cf7c5fd92015300af0beb57"
                    rel="nofollow noreferrer"
                  >
                    <Icon
                      icon={
                        <IconSVG name={'social/dzen'} width={24} height={24} />
                      }
                      title="dzen.yandex"
                    />
                  </a>
                  <Anchor
                    weight="800"
                    href="https://kruiz.online/blog"
                    rel="nofollow"
                    variant={
                      urlBF === 'black_friday'
                        ? 'linkAsbuttonBf'
                        : 'linkAsbutton'
                    }
                  >
                    Блог
                  </Anchor>
                </div>
                <div
                  className={
                    urlBF === 'black_friday'
                      ? styles.copyrightBf
                      : styles.copyright
                  }
                >
                  © {new Date().getFullYear()} kruiz.online
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
