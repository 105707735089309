import React from 'react';
import styles from '../mobileHeader.module.sass';
import { Anchor, Text } from '../../../../Text';
import {
  riverBestReviewsShips,
  riverProfitShips,
  seaBestReviewsShips,
  seaProfitShips,
} from '../../Header/HeaderShips/arraysHeaderShips';

export const MobileHeaderShips = ({ isSea, shipsSea, isMobile, urlBF }) => {
  const profitShips = isSea || shipsSea ? seaProfitShips : riverProfitShips;
  const bestReviewsShips =
    isSea || shipsSea ? seaBestReviewsShips : riverBestReviewsShips;

  return (
    <>
      <a
        className={
          urlBF === 'black_friday' ? styles.allLinksBf : styles.allLinks
        }
        href={isSea ? '/liners' : '/ships'}
        rel="noopener noreferrer"
      >
        {isSea ? 'Все лайнеры' : 'Все теплоходы'}
      </a>
      <Text
        className={
          urlBF === 'black_friday' ? styles.infoWrapperBf : styles.infoWrapper
        }
        weight="bold"
      >
        Выгодно
      </Text>
      <ul className={styles.featureList}>
        {profitShips.map((ship, key) => (
          <li key={key}>
            <Anchor
              href={ship.linkProfitShip}
              rel="noreferrer"
              className={urlBF === 'black_friday' && styles.colorText}
            >
              {ship.nameProfitShip}
            </Anchor>
          </li>
        ))}
      </ul>
      <div className={urlBF === 'black_friday' && styles.colorText}>
        <Text weight="bold">Лучшие отзывы</Text>
      </div>

      <ul className={styles.featureList}>
        {bestReviewsShips.map((bestRivShip, key) => (
          <li key={key}>
            <Anchor
              href={bestRivShip.linkBestRevShip}
              rel="noreferrer"
              className={urlBF === 'black_friday' && styles.colorText}
            >
              {bestRivShip.nameBestRevShip}
            </Anchor>
          </li>
        ))}
      </ul>
    </>
  );
};
